import { useCallback, useContext } from 'react';

import { ButtonIcon, PlayerPauseSvg, PlayerPlaySvg } from '@canalplus/dive';

import { MinimalPlayerContext } from '../../../../context';
import { useAriaLabels, useMinimalPlayerIsPlaying } from '../../../../hooks';
import { Clickable } from '../../../clickable/Clickable';
import { CONTROL_BUTTON_VARIANTS, TControlButtonVariants } from '../types';

import styles from '../ControlButton.css';

interface IDefaultProps {
  variant?: TControlButtonVariants;
}

export interface IProps extends IDefaultProps {
  onPlay?: () => void | undefined;
  onPause?: () => void | undefined;
}

const PlayPauseControlButton = ({
  variant = CONTROL_BUTTON_VARIANTS.DEFAULT,
  onPlay,
  onPause,
}: IProps): JSX.Element | null => {
  const { minimalPlayer } = useContext(MinimalPlayerContext);
  const ariaLabels = useAriaLabels(minimalPlayer, ['Play', 'Pause']);
  const isPlaying = useMinimalPlayerIsPlaying(minimalPlayer);

  const onPlayPauseClick = useCallback((): void => {
    if (isPlaying) {
      onPause?.();
      minimalPlayer.pause();
    } else {
      onPlay?.();
      minimalPlayer.play();
    }
  }, [minimalPlayer, onPlay, onPause, isPlaying]);

  if (!minimalPlayer) {
    return null;
  }

  switch (variant) {
    case CONTROL_BUTTON_VARIANTS.VIDEO:
      return (
        // Let the client import the css from dive if they want to consume this variant
        <ButtonIcon
          variant={CONTROL_BUTTON_VARIANTS.VIDEO}
          aria-label={isPlaying ? ariaLabels.Pause : ariaLabels.Play}
          icon={isPlaying ? <PlayerPauseSvg /> : <PlayerPlaySvg />}
          onClick={onPlayPauseClick}
        />
      );
    case CONTROL_BUTTON_VARIANTS.DEFAULT:
    default:
      return (
        <Clickable
          customStyle={styles.control}
          onClick={onPlayPauseClick}
          ariaLabel={isPlaying ? ariaLabels.Pause : ariaLabels.Play}
        >
          {isPlaying ? <PlayerPauseSvg /> : <PlayerPlaySvg />}
        </Clickable>
      );
  }
};

export { PlayPauseControlButton };
